.embla {
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: 19rem;
    padding: 1.6rem;
    position: relative;
}
.embla__viewport {
    overflow: hidden;
}
.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
}
.embla__slide__img {
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
}
.embla__dot {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
}
.embla__dots {
    z-index: 1;
    bottom: -10px;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.embla__dot {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}
.embla__dot:after {
    background-color: #3f51b573;
    border-radius: 0.2rem;
    width: 100%;
    height: 0.3rem;
    content: '';
}
.embla__dot--selected:after {
    background-color: #3f51b5
}
.embla__button {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
}
.embla__buttons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.embla__buttons_left {
    left: 1.6rem;
}
.embla__buttons_right {
    right: 1.6rem;
}
.embla__button {
    z-index: 1;
    color: #3f51b5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 4rem;
    height: 4rem;
}
.embla__button:disabled {
    opacity: 0.3;
}
.embla__button__svg {
    width: 65%;
    height: 65%;
}